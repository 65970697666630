<template>
    <div v-if="isLoaded" class="staff-month" id="staff-month">
        <div class="staff-month__header">
            <h2 class="staff-month__title h2">Сотрудники месяца</h2>
            <a
                href="javascript:void(0)"
                class="staff-month__settings-btn"
                @click="showStaffModal"
                v-if="current_user.is_admin || current_user.is_chief"
            >
                <svg class="staff-month__icon" width="15" height="15" viewBox="0 0 20 20" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19.4002 12.1916L17.5619 10.7541C17.6189 10.2524 17.6189 9.74585 17.5619 9.24413L19.4019 7.80582C19.7467 7.52813 19.8388 7.04117 19.6194 6.65664L17.7069 3.35084C17.4866 2.96389 17.0175 2.7949 16.6011 2.95252L14.4344 3.82419C14.0272 3.52881 13.5911 3.27556 13.1327 3.06838L12.8002 0.766707C12.7403 0.324833 12.3612 -0.00360296 11.9152 2.98427e-05H8.08191C7.63969 -0.0026264 7.26364 0.322138 7.20192 0.760027L6.8686 3.06834C6.41161 3.27752 5.97618 3.53095 5.5686 3.82501L3.39607 2.95166C2.98397 2.7892 2.51526 2.95615 2.29858 3.34248L0.384407 6.65414C0.159057 7.0393 0.25183 7.5316 0.601906 7.80832L2.44022 9.24581C2.38268 9.74749 2.38268 10.2541 2.44022 10.7558L0.600227 12.1933C0.254838 12.4706 0.16226 12.9578 0.381907 13.3425L2.29358 16.6491C2.51358 17.0364 2.98299 17.2055 3.3994 17.0475L5.56607 16.1758C5.97356 16.4713 6.40997 16.7248 6.86856 16.9325L7.20188 19.2325C7.26055 19.6732 7.63731 20.0017 8.08188 20H11.9152C12.3582 20.0035 12.7355 19.6786 12.7977 19.24L13.131 16.9316C13.5879 16.7224 14.0233 16.4689 14.431 16.175L16.606 17.0491C17.0181 17.2112 17.4866 17.0444 17.7035 16.6583L19.6235 13.3333C19.8384 12.9494 19.7439 12.4663 19.4002 12.1916ZM9.99855 14.1666C7.69739 14.1666 5.83188 12.3012 5.83188 9.99995C5.83188 7.69875 7.69735 5.83329 9.99855 5.83329C12.2997 5.83329 14.1652 7.69875 14.1652 9.99995C14.1624 12.3 12.2986 14.1639 9.99855 14.1666Z"
                        fill="#E0E3F0"/>
                </svg>
            </a>
        </div>
        <div class="staff-month__body">
            <swiper v-if="staffMonthCount >= 1" class="staff-month__slider" id="staff-month-slider" :options="swiperOption" ref="staffSwiper">
                <fragment v-for="year in staffMonth.results" :key="year.id">
                    <swiper-slide class="staff-month__item" v-for="month in year.months" v-if="month.interim.length"
                                  :key="month.month">
                        <span class="staff-month__subtitle">{{MONTHS_NAMES[month.month]}} {{year.year}}</span>
                        <ul class="staff-month__staff-list">
                            <li
                                v-for="user in month.interim"
                                class="staff-month__staff-item"
                                v-tooltip="{
                                        content: user.merit,
                                        offset: 7
                                    }"

                            >
                                <router-link class="staff-month__meta" :to="user.user.link">
                                    <div class="staff-month__avatar-wrap">
                                        <img
                                            class="staff-month__avatar"
                                            :src="user.user.image_zoom_out || defaultAvatar"
                                            :alt="user.user.full_name"
                                            @error="errorAvatar"
                                        >
                                    </div>
                                    <span class="staff-month__name">{{user.user.short_name}}</span>
                                </router-link>
                            </li>
                        </ul>
                    </swiper-slide>
                </fragment>
                <div class="staff-month__btn-prev" slot="button-prev"></div>
                <div class="staff-month__btn-next" slot="button-next"></div>
            </swiper>
            <span v-else class="staff-month__empty">Скоро здесь появятся лучшие из лучших. Может быть Вы будете одним из них?</span>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import defaultAvatar from 'assets/img/avatars/default_avatar.jpg';

    export default {
        name: "StaffMonth",
        data() {
            return {
                swiperOption: {
                    slidesPerView: 'auto',
                    spaceBetween: 70,
                    navigation: {
                        nextEl: '.staff-month__btn-next',
                        prevEl: '.staff-month__btn-prev',
                    },
                    containerModifierClass: 'staff-month__slider-',
                    slideClass: 'staff-month__slide',
                    slideActiveClass: 'staff-month__slide-active'
                },
                isLoaded: false,
                defaultAvatar
            }
        },
        props: {
            windowSize: {
                type: Number,
                required: true
            }
        },
        computed: {
            swiper() {
                return this.$refs.staffSwiper.swiper
            },
            staffMonthCount() {
                return this.staffMonth.results.reduce((totalUsers, currentYear) => {
                    if (!currentYear) {
                        return 0;
                    }
                    let monthCount = currentYear.months.reduce((monthCount, currentMonth) => monthCount + currentMonth.interim.length, 0);
                    return totalUsers + monthCount;
                }, 0)
            },
            ...mapState('staff_month', [
                'staffMonth'
            ]),
            ...mapState('default_data', [
                'current_user', 'MONTHS_NAMES'
            ])
        },
        methods: {
            showStaffModal() {
                const isAdmin = this.current_user.is_admin;
                const AdminModal = () => import(`./settings/AdminModal`);
                const ChiefModal = () => import(`./settings/ChiefModal`);
                this.$modal.show(isAdmin ? AdminModal : ChiefModal, {}, {
                    name: isAdmin ? 'AdminModal' : 'ChiefModal',
                    width: '100%',
                    maxWidth: 900,
                    height: 'auto',
                });
            },
            errorAvatar(event) {
                event.target.setAttribute('src', this.defaultAvatar);
            }
        },
        async mounted() {
            await this.$store.dispatch('staff_month/setStaffMonth');
            this.isLoaded = true;
        }
    }
</script>

<style lang="sass">
    @import "#sass/v-style"

    .staff-month
        margin-bottom: 60px

        &__header
            display: flex
            align-items: center

        &__slider
            width: 100%

        &__settings-btn
            margin-left: 15px
            height: 25px
            width: 25px
            display: flex
            justify-content: center
            align-items: center
            background: #ffffff
            -webkit-border-radius: 50%
            -moz-border-radius: 50%
            border-radius: 50%

            &:hover
                border: 1px solid $gold

                path
                    fill: $gold

            path
                fill: #E0E3F0

        &__body
            display: flex
            margin-top: 15px

        &__item
            display: flex
            flex-direction: column
            align-items: center

            &:first-child
                margin-left: 0

        &__subtitle
            color: #a4aebd
            font-size: 1rem
            font-weight: 600
            text-transform: uppercase
            letter-spacing: 0.06em
            cursor: default
            white-space: nowrap

        &__staff-list
            display: flex
            margin-top: 10px !important

        &__staff-item
            display: flex
            justify-content: center
            margin-left: 15px
            max-width: 100px
            width: 100px

            &:first-child
                margin-left: 0

            &:hover img
                border: 2px solid #FBC04F

        &__meta
            display: flex
            flex-direction: column
            align-items: center
            text-align: center

        &__name
            text-align: center
            color: #A4AEBD
            font-size: 1rem
            margin-top: 10px

        &__avatar
            height: 100px
            width: 100px
            border-radius: 50%
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07)
            border: 2px solid #FFF
            background: #fff
            padding: 3.5px

            &-wrap
                position: relative
                display: flex
                align-items: center

                &::before
                    display: none
                    content: 'Сотрудник компании'
                    font-size: 0.625rem
                    border: 3px solid #F0F2F5
                    position: absolute
                    bottom: 0
                    left: 0
                    width: 100%
                    color: #161616
                    background: #FBC04F
                    border-radius: 10px

        &__slide
            & .staff-month__staff-item
                transition: opacity .1s ease-in
                opacity: 0.5
                &:hover
                    opacity: 1
            transition: opacity .15s ease-in-out

            &:first-child
                & .staff-month__staff-item
                    opacity: 1

                .staff-month__name
                    color: #000

        &__btn
            &-next,
            &-prev
                position: absolute
                width: 70px
                top: 0
                height: 100%
                display: flex
                align-items: center
                justify-content: flex-end
                background: linear-gradient(269.42deg, #F0F2F5 40.04%, rgba(240, 242, 245, 0) 98.63%)
                z-index: 5
                cursor: pointer

                &::before
                    content: ''
                    background-image: $next-arrow
                    height: 100%
                    width: 100%
                    background-repeat: no-repeat
                    background-position: 100% 50%

                &.swiper-button-disabled
                    display: none

            &-prev
                left: 0
                transform: rotate(180deg)

            &-next
                right: 0

        &__empty
            font-size: 1rem
            color: rgba(0, 0, 0, .4)


</style>
